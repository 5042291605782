import { Link } from "gatsby"
import React from "react"
import styled, { ThemeProvider } from "styled-components"
import { Normalize } from "styled-normalize"
import Headline from "../components/common/headline/headline.component"
import GlobalStyle from "../components/global-style.component"
import SEO from "../components/seo"
import VooomLogo from "../images/vooom-logo-color.svg"
import theme from "../theming/theme"
import { textColor } from "../theming/theme-getters"

// Warning! This page is not made by design guidelines. It's a quick shot
// to just prevent the Netlify 404 default page.

const Container = styled.div`
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const BackLink = styled(Link)`
  color: ${textColor("link")};
`

const WideHeadline = styled(Headline)`
  width: 100%;
`

const NotFountPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <Normalize />
        <GlobalStyle />
        <SEO title={"Vooom — nie znaleziono strony"} />
        <Container>
          <Link to={"/"}>
            <VooomLogo />
          </Link>
          <WideHeadline title={"404"} paragraph={"Nie znaleziono strony."} />
          <BackLink to={"/"}>Wróć do strony głównej</BackLink>
        </Container>
      </>
    </ThemeProvider>
  )
}

export default NotFountPage
